import Chip from '@material-ui/core/Chip'
import {useRecordContext} from "react-admin";

export const StringsField = ({ source }: any) => {
    const record = useRecordContext()
    const array = record[source]
    if (typeof array === 'undefined' || array === null) {
        return <div/>
    } else if (array.length === 0) {
        return <>-</>
    } else {
        return (
            <>
                {array.map((item: string) => <Chip label={item} key={item} style={{ marginRight: '5px' }}/>)}
            </>
        )
    }
}

StringsField.defaultProps = { addLabel: true }