import {Labeled, TextInput} from "react-admin";
import React from "react";
import {Grid} from "@material-ui/core";

export const AddressContentInput = ({ source }: any) => {
    return (
        <>
                <h5>Order Address</h5>
                <Grid container spacing={2}>
                        <Grid item xs={12} sm={4} lg={2}>
                                <Labeled label="Company">
                                        <TextInput source={source+".company"} fullWidth />
                                </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={4} lg={2}>
                                <Labeled label="Addressee">
                                        <TextInput source={source+".addressee"} fullWidth />
                                </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={4} lg={2}>
                                <Labeled label="Address">
                                        <TextInput source={source+".address"} fullWidth />
                                </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={4} lg={2}>
                                <Labeled label="Postcode">
                                        <TextInput source={source+".postcode"} fullWidth />
                                </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={4} lg={2}>
                                <Labeled label="City">
                                        <TextInput source={source+".city"} fullWidth />
                                </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={4} lg={2}>
                                <Labeled label="Country">
                                        <TextInput source={source+".country"} fullWidth />
                                </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={4} lg={2}>
                                <Labeled label="Phone">
                                        <TextInput source={source+".phone"} fullWidth />
                                </Labeled>
                        </Grid>
                </Grid>

                <h5>Contact Information</h5>
                <Grid container spacing={2}>
                        <Grid item xs={12} sm={4} lg={2}>
                                <Labeled label="First Name">
                                        <TextInput source={source+".contact.firstName"} fullWidth />
                                </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={4} lg={2}>
                                <Labeled label="Last Name">
                                        <TextInput source={source+".contact.lastName"} fullWidth />
                                </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={4} lg={2}>
                                <Labeled label="Email">
                                        <TextInput source={source+".contact.email"} fullWidth />
                                </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={4} lg={2}>
                                <Labeled label="Phone">
                                        <TextInput source={source+".contact.phone"} fullWidth />
                                </Labeled>
                        </Grid>
                </Grid>

                <h5>Order Details</h5>
                <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                                <Labeled label="Comment">
                                        <TextInput source={source+".comment"} fullWidth />
                                </Labeled>
                        </Grid>
                </Grid>
        </>
    )
}