import {EmailField, Labeled, TextField, useRecordContext} from "react-admin";
import React from "react";
import { Grid } from '@material-ui/core';

export const AddressContentField = ({ source }: any) => {
const record = useRecordContext()
    return (
        <>
                <Grid container spacing={2} key={record.id}>
                        <Grid item xs={12} sm={4}>
                                Company: <TextField source={source+".company"} emptyText="-" /><br />
                                Phone: <TextField source={source+".phone"} emptyText="-" /><br />
                                <address>
                                        <TextField source={source+".addressee"} emptyText="-" /><br />
                                        <TextField source={source+".address"} /><br />
                                        <TextField source={source+".city"} /> <TextField source={source+".postcode"} /> <TextField source={source+".country"} />
                                </address>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                                Name: <TextField source={source+".contact.firstName"} /> <TextField source={source+".contact.lastName"}  emptyText="-" /><br />
                                Email:  <EmailField source={source+".contact.email"} /><br />
                                Phone: <TextField source={source+".contact.phone"} emptyText="-" /><br />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                                <Labeled label="Comment">
                                        <TextField source={source+".comment"} emptyText="-" />
                                </Labeled>
                        </Grid>
                </Grid>
        </>
    )
}