import {
  BooleanField,
  Datagrid,
  List,
  TextField,
  BooleanInput,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  Show,
  SimpleShowLayout,
  TopToolbar,
  ListButton,
  EditButton,
  ShowButton,
  ArrayInput, SimpleFormIterator,
} from "react-admin";
import React from "react";
import AccountBoxOutlined from "@mui/icons-material/AccountBoxOutlined";
import {resourceManager} from "../../helpers/Resources";
import {StringsField} from "../fields/StringsField";
import {OrderTypeField, OrderTypeInput} from "../fields/OrderTypeInput";
import {TemplateListInput} from "../fields/TemplateListInput";

export const showAction = () => (
  <>
    <Show actions={
      <TopToolbar>
        <ListButton />
        <EditButton />
      </TopToolbar>
    }>
      <SimpleShowLayout>
        <TextField source="label" fullWidth />
        <BooleanField source="partnerCenterEnabled" fullWidth />
        <BooleanField source="onSiteEnabled" fullWidth />
        <BooleanField source="autoinstaller" fullWidth />
        <StringsField source="deviceTypes" />
        <OrderTypeField source="orderTypes" />
        <StringsField source="skus" />
        <TextField source="template" fullWidth />
      </SimpleShowLayout>
    </Show>
  </>
);

export const editAction = () => (
  <>
    <Edit actions={
      <TopToolbar>
        <ListButton />
        <ShowButton />
      </TopToolbar>
    }>
      <SimpleForm>
        <TextInput source="label" fullWidth />
        <BooleanInput source="partnerCenterEnabled" fullWidth />
        <BooleanInput source="onSiteEnabled" fullWidth />
        <BooleanInput source="autoinstaller" fullWidth />
        <ArrayInput source="deviceTypes">
          <SimpleFormIterator>
            <TextInput source="" />
          </SimpleFormIterator>
        </ArrayInput>
        <OrderTypeInput source="orderTypes" multiple />
        <ArrayInput source="skus">
          <SimpleFormIterator>
            <TextInput source="" />
          </SimpleFormIterator>
        </ArrayInput>
        <TemplateListInput source="template" fullWidth />
      </SimpleForm>
    </Edit>
  </>
);

export const createAction = () => (
  <>
    <Create actions={
      <TopToolbar>
        <ListButton />
      </TopToolbar>
    }>
      <SimpleForm>
        <TextInput source="label" fullWidth />
        <BooleanInput source="partnerCenterEnabled" fullWidth />
        <BooleanInput source="onSiteEnabled" fullWidth />
        <BooleanInput source="autoinstaller" fullWidth />
        <ArrayInput source="deviceTypes">
          <SimpleFormIterator>
            <TextInput source="" />
          </SimpleFormIterator>
        </ArrayInput>
        <OrderTypeInput source="orderTypes" multiple />
        <ArrayInput source="skus">
          <SimpleFormIterator>
            <TextInput source="" />
          </SimpleFormIterator>
        </ArrayInput>
        <TemplateListInput source="template" fullWidth />
      </SimpleForm>
    </Create>
  </>
);

const listAction = () => (
    <List exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="label" />
      <TextField source="template" />
    </Datagrid>
  </List>
);

resourceManager.addResource('account-profiles', "Paramétrage des offres", AccountBoxOutlined,{
  list: listAction,
  create: createAction,
  show: showAction,
  edit: editAction,
})
