import {Call} from "./utils";
import BaseApiProvider from "./BaseApiProvider";

const MarketApi = {
    ...BaseApiProvider,

    importAccounts: async (file: File) => {
        // @ts-ignore
        const content = btoa(String.fromCharCode(...new Uint8Array(await file.arrayBuffer())));
        return await Call("POST", "/accounts/import-accounts", {
            xls_base64: content,
        }).then((result) => {
            return result?.imported ?? 0;
        });
    },

    checkToken: async (token: string) => {
        localStorage.setItem('token', token)
        const authenticated = await Call("POST", "/operators/search")
            .then((response) => {
                return response.data !== undefined;
            })
        ;
        localStorage.removeItem('token')
        return authenticated
    },

    getTemplateList: async () => {
        return await Call("POST", "/orders/template-list")
            .then((result) => {
                return result?.list;
            })
        ;
    },

    bundle: async (now: boolean) => {
        return await Call("POST", "/orders/bundle", {
            'now': now,
        })
            .then((result) => {
                return result?.bundled;
            })
        ;
    },

    bundleBulk: async (ids: any) => {
        return await Call("POST", "/orders/bundle-bulk", {
            'ids': ids,
        })
            .then((result) => {
                return result?.bundled;
            })
        ;
    },

    getBundleFromOrderId: async (orderId: any) => {
        return await Call("GET", `/orders/${orderId}`)
            .then((result) => {
                return result?.bundle;
            })
        ;
    },

    downloadExcel: async (bundleId: any) => {
        return await Call("POST", `/orders/${bundleId}/download-excel`);
    }
};

export default MarketApi;