import {Labeled, useRecordContext} from "react-admin";
import React from "react";
import {Grid, List, ListItem, makeStyles, Typography} from '@material-ui/core';
import Chip from "@material-ui/core/Chip";
import {ArrowDownward} from "@mui/icons-material";
import { Card, CardContent } from '@mui/material';

const useStyles = makeStyles(() => ({
        list: {
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
        },
        listItem: {
                justifyContent: 'center',
        },
}));

export const VehiclesField = () => {
        const record = useRecordContext()
        const classes = useStyles();

        if (!Array.isArray(record.vehicles) && record.vehicles.length > 0) {
                record.vehicles = []
        }
        if (!Array.isArray(record.replacements) && record.replacements.length > 0) {
                record.replacements = []
        }

        if (record.vehicles.length === 0) {
                return null
        }
        let list = record.vehicles
        for (let i = 0; i < record.replacements.length; i++) {
                list[i].alt = record.replacements[i]
        }

        const hasAlt = record.replacements.length > 0

        return (
            <>
            {list.map((item: any, index: any) =>
                <Card style={{marginBottom: 5}} key={index}>
                        <CardContent>
                                <Grid container spacing={2}>
                                        <Grid item sm={3}>
                                                <List className={classes.list}>
                                                        <ListItem className={classes.listItem}>
                                                                <Typography component="span" variant="body2">{item.licensePlate}</Typography>
                                                        </ListItem>
                                                        {hasAlt && item.licensePlate !== item.alt.licensePlate ? (
                                                            <>
                                                                    <ListItem className={classes.listItem}><ArrowDownward /></ListItem>
                                                                    <ListItem className={classes.listItem}>
                                                                            <Typography component="span" variant="body2">{item.alt.licensePlate}</Typography>
                                                                    </ListItem>
                                                            </>
                                                        ): (<></>)}
                                                </List>
                                        </Grid>
                                        <Grid item sm={3}>
                                                <List className={classes.list}>
                                                        <ListItem className={classes.listItem}>
                                                                <Typography component="span" variant="body2">{item.maker}</Typography>
                                                                <Typography component="span" variant="body2" style={{
                                                                        marginLeft: 5,
                                                                        marginRight: 5,
                                                                        color: "rgba(0, 0, 0, 0.6)",
                                                                }}>{item.brand}</Typography>
                                                                <Typography component="span" variant="body2">{item.year}</Typography>
                                                        </ListItem>
                                                        {hasAlt && item.maker !== item.alt.maker && item.brand !== item.alt.brand && item.year !== item.alt.year ? (
                                                            <>
                                                                    <ListItem className={classes.listItem}><ArrowDownward /></ListItem>
                                                                    <ListItem className={classes.listItem}>
                                                                            <Typography component="span" variant="body2">{item.alt.maker}</Typography>
                                                                            <Typography component="span" variant="body2" style={{
                                                                                    marginLeft: 5,
                                                                                    marginRight: 5,
                                                                                    color: "rgba(0, 0, 0, 0.6)",
                                                                            }}>{item.alt.brand}</Typography>
                                                                            <Typography component="span" variant="body2">{item.alt.year}</Typography>
                                                                    </ListItem>
                                                            </>
                                                        ): (<></>)}
                                                </List>
                                        </Grid>
                                        <Grid item sm={3}>
                                                <List className={classes.list}>
                                                        <ListItem className={classes.listItem}>
                                                                <Chip label={item.deviceType} />
                                                        </ListItem>
                                                        {hasAlt && item.deviceType !== item.alt.deviceType ? (
                                                            <>
                                                                    <ListItem className={classes.listItem}><ArrowDownward /></ListItem>
                                                                    <ListItem className={classes.listItem}>
                                                                            <Chip label={item.alt.deviceType} />
                                                                    </ListItem>
                                                            </>
                                                        ): (<></>)}
                                                </List>
                                        </Grid>
                                        <Grid item sm={3}>
                                                <Labeled label="Comment"><Typography component="span" variant="body2">{item.comment}</Typography></Labeled>
                                                <Typography component="span" variant="body2">{item.alt?.comment}</Typography>
                                        </Grid>
                                </Grid>
                        </CardContent>
                </Card>
            )}
            </>
        )
}