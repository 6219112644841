import { useState, useEffect } from "react";
import {required, SelectInput} from "react-admin";
import { Api } from "../../provider/Api";

let cache: any[] = []

async function fetchData() {
  if (cache.length > 0) {
    return cache
  }

  const response = await Api.getTemplateList();
  const data: Array<{ id: any; name: string }> = [];

  response.map((item: any) => {
    return data.push({ id: item, name: item });
  });

  cache = data

  return data;
}

export const TemplateListInput = (source: any) => {
  const [list, setList] = useState([] as any);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    const data = await fetchData();
    setLoading(false);
    return setList(data);
  };

  useEffect(() => {
    setLoading(true);
    getData();
  }, []);

  return (
    <SelectInput source={source.source} choices={list as []} validate={required()} isLoading={loading} />
  );
};
