import * as React from 'react';
import { useRecordContext, Button } from 'react-admin';
import { Api } from "../../provider/Api";
import { DownloadOutlined, DownloadDoneOutlined } from "@mui/icons-material";
import {MouseEvent, useState} from "react";

const DownloadExcelButton = () => {
    const [loading, setLoading] = useState(false);
    const [bundle, setBundle] = useState(null as any);
    const record = useRecordContext();

    if (record?.bundleId === undefined || record?.bundleId === null  || record?.bundleId === "") {
        return <></>
    }

    Api.getBundleFromOrderId(record.id).then((result) => {
        if (bundle !== null) {
            return
        }
        setBundle(result)
    })

    const handleClick = async (e: MouseEvent) => {
        e.stopPropagation();
        Api.downloadExcel(record.bundleId).then((result) => {
            setLoading(false);

            const link = document.createElement('a');
            link.download = result.filename;

            link.href = `data:${result.contentType};base64,${result.data}`;
            link.click();
        });

        setLoading(true);
    }

    const alreadyDownloaded = bundle !== null && bundle.downloadedAt > 0

    return <Button label={bundle?.reference} startIcon={alreadyDownloaded ?
        <DownloadDoneOutlined />
    :
        <DownloadOutlined />
    } onClick={handleClick} disabled={loading} />;
};

export default DownloadExcelButton;