import * as React from 'react';
import {Button, Confirm, useRefresh, useNotify} from 'react-admin';
import { Api } from "../../provider/Api";
import { AccountTreeOutlined } from "@mui/icons-material";
import {useState} from "react";
import {Checkbox, FormControlLabel} from "@material-ui/core";

const BundleButton = () => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [now, setNow] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleNowChange = (event: any) => {
        setNow(event.target.checked);
    };
    const handleConfirm = async () => {
        Api.bundle(now).then((result) => {
            setLoading(false);
            notify(`Bundling finished successfully, created: ${result}.`)
            refresh();
            setOpen(false);
        });
        setLoading(true);
    }

    return <>
        <Button label="Bundle..." startIcon={<AccountTreeOutlined />} onClick={handleClick} disabled={loading} />
        <Confirm
            isOpen={open}
            loading={loading}
            title={`Bundling`}

            content={
                <>
                    <p>Are you sure you want to start the bundling process?</p>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={now}
                                onChange={handleNowChange}
                                color="primary"
                            />
                        }
                        label="Ignore the 24h delay."
                    />
                </>
            }
            onConfirm={handleConfirm}
            onClose={handleDialogClose}
        />
    </>;
};

export default BundleButton;