import {
  Datagrid,
  List,
  TextField,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  Show,
  SimpleShowLayout,
  TopToolbar,
  ListButton,
  EditButton,
  ShowButton,
  FilterButton,
  CreateButton,
} from "react-admin";
import React from "react";
import HandshakeOutlined from "@mui/icons-material/HandshakeOutlined";
import {resourceManager} from "../../helpers/Resources";

export const showAction = () => (
  <>
    <Show actions={
      <TopToolbar>
        <ListButton />
        <EditButton />
      </TopToolbar>
    }>
      <SimpleShowLayout>
        <TextField source="name" fullWidth />
        <TextField source="email" fullWidth />
      </SimpleShowLayout>
    </Show>
  </>
);

export const editAction = () => (
  <>
    <Edit actions={
      <TopToolbar>
        <ListButton />
        <ShowButton />
      </TopToolbar>
    }>
      <SimpleForm>
        <TextInput source="name" fullWidth />
        <TextInput source="email" fullWidth />
      </SimpleForm>
    </Edit>
  </>
);

export const createAction = () => (
  <>
    <Create actions={
      <TopToolbar>
        <ListButton />
      </TopToolbar>
    }>
      <SimpleForm>
        <TextInput source="name" fullWidth />
        <TextInput source="email" fullWidth />
      </SimpleForm>
    </Create>
  </>
);

const filters = [
  <TextInput source="label" />,
];

const Actions = () => (
    <TopToolbar>
      <FilterButton />
      <CreateButton />
    </TopToolbar>
);

const listAction = () => (
    <List exporter={false} actions={<Actions />} filters={filters} >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="email" />
    </Datagrid>
  </List>
);

resourceManager.addResource('commercial-attachments', "Gestion rat. com.", HandshakeOutlined,{
  list: listAction,
  create: createAction,
  show: showAction,
  edit: editAction,
})
