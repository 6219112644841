import * as React from 'react';
import {Button, useRefresh, useNotify, BulkActionProps} from 'react-admin';
import { Api } from "../../provider/Api";
import { AccountTreeOutlined } from "@mui/icons-material";
import {ReactElement, useState} from "react";

const BundleBulkButton = ({
                              resource,
                              selectedIds,
                          }: BulkActionProps): ReactElement | null => {
    const [loading, setLoading] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();

    const handleClick = async () => {
        Api.bundleBulk(selectedIds).then((result) => {
            setLoading(false);
            notify(`Bundling finished successfully, created: ${result}.`)
            refresh();
        });
        setLoading(true);
    }

    return <>
        <Button label="Bundle selection..." startIcon={<AccountTreeOutlined />} onClick={handleClick} disabled={loading} />
    </>;
};

export default BundleBulkButton;