import {Auth0Client} from "@auth0/auth0-spa-js";
import {AuthProvider, PreviousLocationStorageKey, UserIdentity} from "react-admin";

type Auth0AuthProviderOptions = {
    loginRedirectUri?: string;
    logoutRedirectUri?: string;
    redirectOnCheckAuth?: boolean;
};

async function handleCallback(client: Auth0Client) {
    const query = window.location.search;
    if (query.includes('code=') && query.includes('state=')) {
        try {
            await client.handleRedirectCallback();
            if (await client.isAuthenticated()) {
                const token = await client.getIdTokenClaims()
                localStorage.setItem("access_token", "" + token?.__raw)
            }
            return;
        } catch (error) {
            throw error;
        }
    }
    throw new Error('Failed to handle login callback.');
}

export function createAuth0AuthProvider(client: Auth0Client, options: Auth0AuthProviderOptions = {}): AuthProvider {
    const {
        loginRedirectUri,
        logoutRedirectUri,
        redirectOnCheckAuth = true,
    } = options;

    return {
        async login() {
            client.loginWithRedirect({
                authorizationParams: {
                    redirect_uri:
                        loginRedirectUri ??
                        `${window.location.origin}/auth-callback`,
                },
            });
        },

        async logout() {
            localStorage.removeItem("access_token")
            return client.logout({
                logoutParams: {
                    returnTo: logoutRedirectUri || window.location.origin + "/logout",
                },
            });
        },

        async checkError({ status }) {
            if (status === 401 || status === 403) {
                throw new Error('Unauthorized');
            }
        },

        async checkAuth() {
            const isAuthenticated = await client.isAuthenticated();
            if (isAuthenticated) {
                return;
            }

            if (window.location.href.includes("auth-callback")) {
                await handleCallback(client)
                window.location.href = window.location.origin
                return;
            }

            if (redirectOnCheckAuth) {
                localStorage.setItem(
                    PreviousLocationStorageKey,
                    window.location.href
                );
                client.loginWithRedirect({
                    authorizationParams: {
                        redirect_uri:
                            loginRedirectUri ??
                            `${window.location.origin}/auth-callback`,
                    },
                });
            }
        },

        async getPermissions() {
            if (!(await client.isAuthenticated())) {
                return [];
            }

            const claims = await client.getIdTokenClaims();
            if (claims === undefined) {
                return []
            }

            const roleProperty = Object.keys(claims).find(key =>
                key.includes('role')
            );

            return claims["" + roleProperty] ?? [];
        },

        async getIdentity(): Promise<UserIdentity> {
            if (await client.isAuthenticated()) {
                const user = await client.getUser();
                if (user) {
                    return {
                        id: user.email ?? '',
                        fullName: user.name,
                        avatar: user.picture,
                    }
                }
            }
            throw new Error('Failed to get identity.');
        },

        async handleCallback() {
            await handleCallback(client);
        },
    };
}
