import {
  BooleanField,
  Datagrid,
  List,
  TextField,
  BooleanInput,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  Show,
  SimpleShowLayout,
  TopToolbar,
  ListButton,
  EditButton,
  ShowButton,
  FilterButton,
  CreateButton,
  ReferenceArrayField,
  ReferenceArrayInput, ReferenceInput, ReferenceField, SingleFieldList, ChipField,
} from "react-admin";
import React from "react";
import StoreOutlined from "@mui/icons-material/StoreOutlined";
import {resourceManager} from "../../helpers/Resources";
import UploadAccountsButton from "../buttons/UploadAccountsButton";

export const showAction = () => (
  <>
    <Show actions={
      <TopToolbar>
        <ListButton />
        <EditButton />
      </TopToolbar>
    }>
      <SimpleShowLayout>
        <TextField source="label" fullWidth />
        <TextField source="number" fullWidth />
        <TextField source="country" fullWidth />
        <BooleanField source="autoinstaller" fullWidth />
        <ReferenceField source="commercialAttachmentId" reference="commercial-attachments" fullWidth />
        <ReferenceArrayField source="accountProfileIds" reference="account-profiles" fullWidth >
          <SingleFieldList>
            <ChipField source="label" />
          </SingleFieldList>
        </ReferenceArrayField>
      </SimpleShowLayout>
    </Show>
  </>
);

export const editAction = () => (
  <>
    <Edit actions={
      <TopToolbar>
        <ListButton />
        <ShowButton />
      </TopToolbar>
    }>
      <SimpleForm>
        <TextInput source="label" fullWidth />
        <TextInput source="number" fullWidth />
        <TextInput source="country" fullWidth />
        <BooleanInput source="autoinstaller" fullWidth />
        <ReferenceInput source="commercialAttachmentId" reference="commercial-attachments" fullWidth />
        <ReferenceArrayInput source="accountProfileIds" reference="account-profiles" fullWidth />
      </SimpleForm>
    </Edit>
  </>
);

export const createAction = () => (
  <>
    <Create actions={
      <TopToolbar>
        <ListButton />
      </TopToolbar>
    }>
      <SimpleForm>
        <TextInput source="label" fullWidth />
        <TextInput source="number" fullWidth />
        <TextInput source="country" fullWidth />
        <BooleanInput source="autoinstaller" fullWidth />
        <ReferenceInput source="commercialAttachmentId" reference="commercial-attachments" fullWidth />
        <ReferenceArrayInput source="accountProfileIds" reference="account-profiles" fullWidth />
      </SimpleForm>
    </Create>
  </>
);

const filters = [
  <ReferenceInput source="commercialAttachmentId" reference="commercial-attachments" />,
  <ReferenceInput source="accountProfileId" reference="account-profiles" />,
  <TextInput source="number" />,
];

const Actions = (props: any) => (
    <TopToolbar>
      <FilterButton />
      <CreateButton />
      <UploadAccountsButton />
    </TopToolbar>
);

const listAction = () => (
    <List exporter={false} actions={<Actions />} filters={filters} >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="label" />
      <TextField source="number" />
      <TextField source="country" />
      <BooleanField source="autoinstaller" />
    </Datagrid>
  </List>
);

resourceManager.addResource('accounts', "Comptes clients", StoreOutlined,{
  list: listAction,
  create: createAction,
  show: showAction,
  edit: editAction,
})
