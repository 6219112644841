import {
    Datagrid,
    List,
    TextField,
    Show,
    SimpleShowLayout,
    TopToolbar,
    ListButton,
    FilterButton,
    ReferenceInput,
    ReferenceField,
    useRecordContext,
    SelectInput,
    Labeled,
    TabbedShowLayout,
    BooleanField,
    RecordContextProvider, TextInput,
} from "react-admin";
import React, {Fragment, useEffect, useState} from "react";
import ShoppingBasketOutlined from "@mui/icons-material/ShoppingBasketOutlined";
import {resourceManager} from "../../helpers/Resources";
import {Api} from "../../provider/Api";
import DownloadExcelButton from "../buttons/DownloadExcelButton";
import {OrderTypeField} from "../fields/OrderTypeInput";
import {Grid} from "@material-ui/core";
import {VehiclesField} from "../fields/VehiclesField";
import {AddressContentField} from "../fields/AddressContentField";
import BundleButton from "../buttons/BundleButton";
import {DateFieldForTimestamp} from "../fields/DateFieldForTimestamp";
import BundleBulkButton from "../buttons/BundleBulkButton";

const OrderView = () => {
    const record = useRecordContext()
    const [deliveryAddress, setDeliveryAddress] = useState({} as any)
    const [locationAddress, setLocationAddress] = useState({} as any)
    const [partnerCenter, setPartnerCenter] = useState({} as any)

    const getData = async () => {
        const deps = [{
            resource: 'addresses',
            method: setDeliveryAddress,
            id: record.deliveryAddressId,
        },{
            resource: 'addresses',
            method: setLocationAddress,
            id: record.locationAddressId,
        },{
            resource: 'partner-centers',
            method: setPartnerCenter,
            id: record.partnerCenterId,
        }]

        for (const dep of deps) {
            if (dep.id === undefined || dep.id === "") {
                continue;
            }
            const value = await Api.getOne(dep.resource, {id: dep.id})
            dep.method(value.data);
        }
    };

    useEffect( () => {
        const fetchData = async () => {
            await getData();
        };
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4} lg={2}>
                <Labeled><OrderTypeField source="type" /></Labeled>
            </Grid>
            <Grid item xs={12} sm={4} lg={2}>
                <Labeled><ReferenceField source="accountId" reference="accounts" /></Labeled>
            </Grid>
            <Grid item xs={12} sm={4} lg={2}>
                <Labeled><BooleanField source="autoinstaller" /></Labeled>
            </Grid>
        </Grid>

        <TabbedShowLayout>
            <TabbedShowLayout.Tab label="Info">
                {deliveryAddress?.content !== undefined ?
                    <>
                        <h5>Delivery Address</h5>
                        <RecordContextProvider value={deliveryAddress}>
                            <AddressContentField source="content" />
                        </RecordContextProvider>
                    </>
                : <></>}
                {locationAddress?.content !== undefined ?
                    <>
                        <h5>Location Address</h5>
                        <RecordContextProvider value={locationAddress}>
                            <AddressContentField record={locationAddress} source="content" />
                        </RecordContextProvider>
                    </>
                    : <></>}
                {partnerCenter?.address !== undefined ?
                    <>
                        <h5>Location Address</h5>
                        <RecordContextProvider value={partnerCenter}>
                            <AddressContentField record={partnerCenter} source="address" />
                        </RecordContextProvider>
                    </>
                    : <></>}
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label="Vehicles" style={{display: record?.vehicles.length === 0 ? "none" : ""}}>
                <VehiclesField />
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label="Products" style={{display: Object.keys(record?.products).length === 0 ? "none" : ""}}>
                <ul>
                {Object.keys(record?.products).map(index => (
                    <li key={index}><strong>{record.products[index].sku}</strong> &times; {record.products[index].quantity}</li>
                ))}
                </ul>
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label="JSON">
                <pre>{JSON.stringify(record, null, '\t')}</pre>
            </TabbedShowLayout.Tab>
        </TabbedShowLayout>
    </>
  )
}

export const showAction = () => (
  <>
    <Show actions={
      <TopToolbar>
        <ListButton />
        <DownloadExcelButton />
      </TopToolbar>
    }>
      <SimpleShowLayout>
        <OrderView />
      </SimpleShowLayout>
    </Show>
  </>
);

const filters = [
    <ReferenceInput source="accountId" reference="accounts" />,
    <SelectInput source="status" choices={[
        { id: 'U', name: 'Unexported' },
        { id: 'E', name: 'Exported' },
    ]} />,
    <TextInput source="bundleCode" />,
];

const Actions = () => (
    <TopToolbar>
        <FilterButton />
        <BundleButton />
    </TopToolbar>
);

const listAction = () => (
    <List exporter={false}
          actions={<Actions />}
          filters={filters}
          sort={{ field: 'createdAt', order: 'DESC' }} >
    <Datagrid rowClick="show" bulkActionButtons={<BundleBulkButton/>}>
      <TextField source="id" />
      <DateFieldForTimestamp source="createdAt" />
      <OrderTypeField source="type" />
      <ReferenceField source="accountId" reference="accounts" />
      <DownloadExcelButton />
    </Datagrid>
  </List>
);

resourceManager.addResource('orders', "Commandes clients", ShoppingBasketOutlined,{
  list: listAction,
  show: showAction,
})
