import {
  Datagrid,
  List,
  TextField,
  Show,
  SimpleShowLayout,
  TopToolbar,
  ListButton,
  ReferenceField,
} from "react-admin";
import React from "react";
import PinDropOutlined from "@mui/icons-material/PinDropOutlined";
import {resourceManager} from "../../helpers/Resources";
import {AddressContentField} from "../fields/AddressContentField";

export const showAction = () => (
  <>
    <Show actions={
      <TopToolbar>
        <ListButton />
      </TopToolbar>
    }>
      <SimpleShowLayout>
        <ReferenceField source="accountId" reference="accounts" />
        <AddressContentField source="content" label={false} />
      </SimpleShowLayout>
    </Show>
  </>
);

const listAction = () => (
    <List exporter={false} >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <ReferenceField source="accountId" reference="accounts" />
      <TextField source="content.addressee" />
      <TextField source="content.address" />
    </Datagrid>
  </List>
);

resourceManager.addResource('addresses', "Gestion d'adresses", PinDropOutlined,{
  list: listAction,
  show: showAction,
})
