import { ChangeEvent, useState } from 'react';
import {useNotify, Button} from 'react-admin';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import UploadOutlined from "@mui/icons-material/UploadOutlined";
import {Api} from "../../provider/Api";

const UploadAccountsButton = () => {
    const [file, setFile] = useState<File>();
    const [open, setOpen] = useState(false);
    const notify = useNotify();

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    const handleUploadClick = () => {
        if (!file) {
            return;
        }

        // 👇 Uploading the file using the fetch API to the server
        Api.importAccounts(file).then(imported => {
            notify("Imported: " + imported);
        }).catch((error: Error) => {
            notify(`Import failed: ${error.message}`, { type: 'error' });
        })
    };

    return (
        <>
            <Button onClick={() => setOpen(true)} endIcon={<UploadOutlined />} label="Import" />
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Import accounts</DialogTitle>
                <DialogContent>
                    <div className="text-center">
                        <input type="file" onChange={handleFileChange} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} label="Cancel"></Button>
                    <Button onClick={handleUploadClick} label="Continue"></Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default UploadAccountsButton;