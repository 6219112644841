import {SelectArrayInput, SelectField, SelectInput, useRecordContext} from "react-admin";
import Chip from "@material-ui/core/Chip";

const data = {
    I: "Installation",
    R: "Reallocation",
    S: "Substitution",
    U: "Uninstallation",
    P: "Products"
}

const choices = Object.entries(data).map(([id, name]) => ({
    id,
    name,
}));

export const OrderTypeInput = ({ source, multiple }: any) => {
    if (multiple) {
        return (
        <SelectArrayInput source={source} choices={choices} />
        )
    }

    return (
        <SelectInput source={source} choices={choices} />
    )
}

export const OrderTypeField = ({ source }: any) => {
    const record = useRecordContext()
    const value = record[source]

    if (Array.isArray(value)) {
        type DataKey = keyof typeof data;
        return (
            <>
                {value.map((item: string) => <Chip label={data[item as DataKey]} key={item} style={{ marginRight: '5px' }}/>)}
            </>
        )
    }

    return (
        <SelectField source={source} choices={choices} />
    )
}